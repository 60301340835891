import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { CgProfile } from 'react-icons/cg';
import Select from 'react-select';
import paises from '../constants/Countries';
import WarningPopup from './WarningPopup';
import { useState, useRef } from 'react';

const validationSchema = Yup.object({
  documento: Yup.string().required("Campo requerido"),
  rut: Yup.string().when("documento", (documento: any, schema) => {
    if (documento[0] == "cedula") {
      return schema
        .matches(
          /^\d{1,2}\d{3}\d{3}[-][0-9kK]{1}$/,
          { message: 'El RUT debe seguir el formato XXXXXXXX-X' }
        )
        .required("El RUT es requerido");
    } else {
      return schema
        .matches(/^[A-Z0-9]+$/, "Número de pasaporte inválido")
        .required("El número de pasaporte es requerido");
    }
  }),
  nacionalidad: Yup.string().when("documento", (documento: any, schema) => {
    if(documento[0] == "pasaporte")
      return schema.required("Campo requerido");
    else
      return schema;
  }),
});

// https://gist.github.com/donpandix/f1d638c3a1a908be02d5
const Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut(rutCompleto: string) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    const tmp: Array<string> = rutCompleto.split('-');
    let digv: string | number = Number(tmp[1]) ? Number(tmp[1]) : tmp[1];
    const rut = Number(tmp[0]);
    if (digv === 'K') digv = 'k';
    return (Fn.dv(rut) === String(digv));
  },
  dv(rut: number) {
    let M = 2;
    let R = rut;
    let suma = 0;
    while (R !== 0) {
      const number = R % 10;
      suma += number * M; // 0 - 7

      R = Math.floor(R / 10);
      M += 1;

      if (M === 8) {
        M = 2;
      }
    }

    const ret = 11 - (suma % 11);
    if (ret === 11) {
      return '0';
    } if (ret === 10) {
      return 'k';
    }
    return String(ret);
  },
};

const colourStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: 'white',
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
    fontWeight: 'lighter',
    fontSize: '14px',
  }),
  menu: (base: any, state: any) => ({
    ...base,
    backgroundColor: 'white',
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
    fontWeight: 'lighter',
    fontSize: '14px',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#80808060',
    fontWeight: 'lighter',
    fontSize: '14px',
  }),

};

const RutContainer = ({ setRutFromThis }: any) => {
  const [showPassportModal, setShowPassportModal] = useState<boolean>(false);
  const setFieldValueRef = useRef<any>(null);
  const [selectedDocument, setSelectedDocument] = useState({ value: 'cedula', label: 'Cédula de identidad' });

  return (
    <div className="mainContainerRutPage">
      <p className="containerTitle">
        <CgProfile style={{ marginRight: '4%' }} size={45} />
        Datos de paciente
      </p>
      <p className="containerSubTitle">Ingrese datos del paciente que será atendido</p>
      <div className="inputContainer">
        <Formik
          initialValues={{
            documento: 'cedula',
            rut: '',
            nacionalidad: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setRutFromThis(values.rut, values.documento, values.nacionalidad);
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            errors, touched, setFieldValue, values,
          }) => {
            setFieldValueRef.current = setFieldValue;
            return (
              <Form>
                <label className="labelForm">Documento de identificación</label>
                <Select
                  value={selectedDocument}
                  placeholder="Selecciona un documento"
                  className="formSelector"
                  styles={colourStyles}
                  options={[
                    { value: 'cedula', label: 'Cédula de identidad' },
                    { value: 'pasaporte', label: 'Pasaporte / DNI' },
                  ]}
                  onChange={(documento: any) => {
                    setSelectedDocument(documento);
                    setFieldValue('documento', documento.value);
                    if (documento.value === 'pasaporte') {
                      setShowPassportModal(true);
                    }
                  }}
                />
                {values.documento === 'pasaporte' && (
                  <>
                    <label className="labelForm">Nacionalidad</label>
                    <Select
                      placeholder="Selecciona una nacionalidad"
                      className="formInput"
                      styles={colourStyles}
                      options={paises.map((item: any) => ({ value: item, label: item }))}
                      onChange={(nacionalidad: any) => {
                        setFieldValue('nacionalidad', nacionalidad.value)
                      }}
                    />
                    {errors.nacionalidad && touched.nacionalidad ? (
                      <div className="error">{String(errors.nacionalidad)}</div>
                    ) : null}
                  </>
                )}
                <label className="labelForm">{values.documento === 'cedula' ? 'RUT' : 'Número de pasaporte'}</label>
                <Field
                  id="rut"
                  name="rut"
                  placeholder={
                    values.documento === "cedula"
                      ? "Ingrese RUT del paciente"
                      : "Ingrese número de pasaporte o DNI"
                  }
                  className="fieldForm"
                  onChange={(e: any) => {
                    setFieldValue('rut', e.target.value);
                  }}
                />
                <p className="rutDescription">{values.documento === 'cedula' ? '*Ejemplo 12345678-9' : '*Solo para extranjeros sin cédula de identidad'}</p>
                {errors.rut && touched.rut
                  ? (<div className="error">{String(errors.rut)}</div>)
                  : null}
                <button className="rutSearchButton" type="submit">Continuar</button>
              </Form>
            );
          }}
        </Formik>

        <WarningPopup
          isOpen={showPassportModal}
          onClose={() => {
            setShowPassportModal(false);
            setFieldValueRef.current('documento', 'cedula');
            setSelectedDocument({ value: 'cedula', label: 'Cédula de identidad' });
          }}
          onContinue={() => {
            setShowPassportModal(false);
          }}
          title="Importante"
          message="Si eres extranjero y tienes RUT nacional, ingresa con tu RUT nacional. Si no lo tienes, puedes ingresar con tu pasaporte."
          subMessage=""
          continueText="Entiendo, continuar con la reserva"
          cancelText="Volver a cédula"
          titleColor="#cc0000"
        />
      </div>
    </div>
  );
};

export default RutContainer;
